import React, { useEffect, useState } from "react"
import { TransitionProvider, TransitionViews } from "gatsby-plugin-transitions"
import { StaticQuery, graphql } from "gatsby"
import "../styles/main.scss"

import Container from "../components/Container"
import Nav from "./Nav"
import Footer from "./Footer"

const GlobalWrapper = ({ data, location, children, props }) => {
  // const [backgroundCol, backgroundColState] = useState([data.backgrounds])
  useEffect(() => {
    // setBackgroundVal()
    document.body.style.background = "#fff"
  })

  // const setBackgroundVal = () => backgroundColState( props )

  return (
    <>
      <StaticQuery
        query={graphql`
          query {
            site {
              siteMetadata {
                author
                description
                title
              }
            }
          }
        `}
        render={() => (
          <div
            className={`${
              children.props.uri.split("/")[1] !== ""
                ? `${children.props.uri.split("/")[1]}-holder`
                : "home-holder"
            } main-parent`}
          >
            <Nav pageLocation={location} />
            <TransitionProvider
              location={location}
              mode="successive"
              enter={{
                opacity: 0,
                config: {
                  mass: 1,
                  tension: 210,
                  friction: 20,
                  duration: 250,
                },
              }}
              usual={{
                opacity: 1,
                clamp: true,
                config: {
                  mass: 1,
                  tension: 210,
                  friction: 20,
                  duration: 250,
                },
              }}
              leave={{
                opacity: 0,
                clamp: true,
                config: {
                  mass: 1,
                  tension: 210,
                  friction: 20,
                  duration: 250,
                },
              }}
            >
              <div className="layout">
                <Container>
                  <TransitionViews>{children}</TransitionViews>
                </Container>
              </div>
            </TransitionProvider>
            <Footer />
          </div>
        )}
      />
    </>
  )
}

export default GlobalWrapper
