import React, { useState, useEffect, useRef } from "react"
import { Link } from "gatsby"

const Nav = ({ pageLocation }) => {
  // const backgroundVal = useRef()
  // useEffect(() => {
  //     props = '#fff'
  //     setTheMenuColor();
  // })

  // const setTheMenuColor = () =>{
  //     const rgbArray = []
  //     // console.log(backgroundVal.current.parentNode.style.background)
  //     const bodyBackgroundVal = backgroundVal.current.parentNode.style.backgroundColor;
  //     const rgbVal = bodyBackgroundVal.match(/\((\d{1,3}), ?(\d{1,3}), ?(\d{1,3})\)?(?:, ?(\d(?:\.\d?))\))?/); // regEx for rgba values

  //     rgbArray.push(rgbVal)

  //     const rgbFilter =  rgbArray.filter( rgb => rgb < 100 ); // filters the amount of rgb values below 100
  //     const rgbFilterLogic = rgbFilter.length >= 3 ? 'white' : 'black'; // set the color / background of the menu icon
  //     return rgbFilterLogic
  // }

  const [toggle, toggleState] = useState(false);
  const path = pageLocation.pathname;
  const toggleTheState = () => {
    toggleState(!toggle)
  }

  useEffect(() => {
    const navElem = document.querySelector('nav')
    navElem.classList.contains('active') ? document.body.style.overflow = 'hidden' : document.body.style.overflow = 'auto'
  })

  return (
    <>
      <div
        className={`${toggle ? "active" : "not-active"} menu-button`}
      >
        <p
          style={{
            color: `${
              path === "/about" || path === "/about/" ? "white" : "black"
            }`,
          }}
          onClick={toggleTheState}
        >
          {toggle ? "Close" : "Menu"}
          <span
            className="animated-underline"
            style={{
              background: `${
                path === "/about" || path === "/about/" ? "white" : "black"
              }`,
            }}
          ></span>
        </p>
      </div>
      {
        path.split("/")[2]  ?
        <p className="back-button"><Link to="/portfolio">/portfolio</Link></p>
        : null
      }
      
      <nav className={`${toggle ? "active" : "not-active"}`}>
        <div className="links">
          <div className="nav-item-inner">
            <Link to="/" onClick={toggleTheState}>
              Home
            </Link>
            <Link to="/portfolio" onClick={toggleTheState}>
              Portfolio
            </Link>
            <Link to="/about" onClick={toggleTheState}>
              About
            </Link>
          </div>
        </div>
        <div className="contact">
          <div className="nav-item-inner">
            <h3>Contact</h3>
            <p>
              email:{" "}
              <a href="mailto:f.gisonni@gmail.com?subject=[Enter Your Name Here] - Web Development - iamfrancesco">f.gisonni@gmail.com</a>
            </p>
          </div>
        </div>
      </nav>
    </>
  )
}

export default Nav
