import React from "react"

const Footer = props => {
  return (
    <footer>
      <p>francesco &copy; all rights reserved</p>
      <p>
        Lets work together -
        Shoot me a message here 👉{" "}
        <a href="mailto:f.gisonni@gmail.com?subject=[Enter Your Name Here] - Web Development - iamfrancesco">f.gisonni@gmail.com</a>
      </p>
    </footer>
  )
}

export default Footer
